@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap);
:root {
  --dark-bg-color: #333;
  --grey-bg-color: #444;
  --text-color: #fff;
  --shade-blue: #afafaf;
  --blue-color: #3d89d1;
  --blue-selector: #5187e0;
  --link-blue: rgb(19, 113, 177);
}

* {
  box-sizing: border-box !important;
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
}
a {
  color: inherit;
}

body {
  font-family: "Open Sans", sans-serif;
}
.relative {
  position: relative;
}

.display-flex {
  display: flex;
  flex-direction: column;
}
.flex {
  flex: 1 1;
}
.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}
.right-align {
  text-align: right;
}

.home {
  display: flex;
  min-height: 100vh;
  overflow: hidden;
}

.row-input-wrapper {
  align-items: center;
  display: flex;
}

.row-input-wrapper-end {
  align-items: center;
  justify-content: flex-end;
  display: flex;
}
.input-wrapper-row-inputbox {
  width: 100px !important;
}
.row-input-wrapper button {
  margin-left: 8px;
}
.row-input-wrapper > div {
  width: 100%;
}
.card-table-title {
  background: var(--grey-bg-color);
  color: var(--text-color);
  padding: 16px;
  letter-spacing: 0.5px;
  font-size: 0.9rem;
}
.card-table-title-multi {
  display: flex;
  align-items: center;
  padding: 8px 16px;
}
.card-table-title-multi p {
  flex: 1 1;
}
.categories-bar {
  margin: 0 16px;
  display: flex;
  align-items: center;
  overflow: hidden;
  min-height: 45px;
}
.categories-bar-dark input::-webkit-input-placeholder {
  color: #bbb;
}
.categories-bar-dark input:-ms-input-placeholder {
  color: #bbb;
}
.categories-bar-dark input::-ms-input-placeholder {
  color: #bbb;
}
.categories-bar-dark input::placeholder {
  color: #bbb;
}
.categories-bar-dark input:-ms-input-placeholder {
  color: #bbb;
}
.categories-bar-dark input::-ms-input-placeholder {
  color: #bbb;
}
.dash {
  flex: 1 1;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.dash h2 {
  font-weight: 400;
  margin-top: 80px;
  margin-left: 32px;
  font-size: 2rem;
}
.dash p {
  margin-left: 32px;
  font-size: 0.8rem;
  font-weight: 600;
}
.illustration-wrapper {
  flex: 1 1;
  overflow: hidden;
}
.illustration {
  width: 90%;
}

.side-label-dash {
  margin: 16px;
}

.notification {
  z-index: 1000;
  position: fixed;
  width: 500px;
  top: 24px;
  left: 50%;
  transform: translateX(-50%);
}

.busy {
  z-index: 1000;
  position: fixed;
  background: var(--blue-color);
  width: 140px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  padding: 4px;
  border-radius: 0 0 20px 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

.busy img {
  height: 23px;
  margin-right: 8px;
  width: auto;
}
.busy p {
  margin-bottom: 3px;
}
.network-text {
  font-weight: 500;
  margin: 5%;
  color: #888;
}

.main-section {
  flex: 1 1;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
}

.table-content {
  margin: 16px;
  overflow: auto;
  position: relative;
  padding: 1px;
  flex: 1 1;
}

.table-th-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: -1px;
  z-index: 100;
  background: #fff;
}
.dark-table-fake .table-th-sticky {
  background: #222;
}

.card-custom {
  position: absolute;
  top: 1;
  left: 1;
  min-width: 99%;
}

.card-multi-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
}

.new-component {
  border: solid 2px rgb(194, 194, 194);
  border: solid 2px rgb(219, 219, 219);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;

  transition: box-shadow 0.2s ease-in-out;
}

.new-component:hover {
  box-shadow: 0px 0px 6px 2px rgba(204, 204, 204, 0.6);
}

.add-svg-wrapper {
  height: 70px;
  width: 70px;
}

.select-wrapper {
  height: 50%;
  padding: 16px;
}

.buttons-wrapper-multi {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
}

.min-new-component {
  min-height: 278px;
}

.logo-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  margin-top: 16px;
}

.logo-wrapper-dark {
  color: #fff !important;
  background: var(--dark-bg-color);
}

.logo-wrapper img {
  height: 100px;
  width: auto;
}

.login-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.login-box-bg {
  background: #eee;
  border: solid 1px rgba(171, 179, 179, 0.7);
  box-shadow: 0 0.125rem 0.25rem rgba(12, 13, 13, 0.15);
  padding: 32px 0;
}

.login-box-bg-dark {
  color: #fff !important;
  background: #232429 !important;
}

.login-box-bg-dark a {
  color: #fff !important;
}

.login-box {
  background: #fff;
  border: solid 1px rgba(171, 179, 179, 0.7);
  border-left: none;
  border-right: none;
  padding: 24px;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  min-width: 400px;
}

.login-box-dark {
  color: #fff !important;
  background: var(--dark-bg-color);
}

.login-form {
  display: flex;
  flex-direction: column;
}

.login-form label {
  font-size: 0.7rem;
  font-weight: 600;
  margin: 16px 0 8px;
}
.login-form a {
  font-size: 0.7rem;
  font-weight: 600;
  color: var(--link-blue);
  margin: 16px 0 8px;
}

.password-recover-wrapper {
  display: flex;
  justify-content: space-between;
}

.form-notifi {
  margin-top: 8px;
  color: rgb(202, 52, 97);
}

.side-label {
  display: flex;
  font-size: 1.1rem;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  -webkit-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
      user-select: none;
}

.side-label img {
  transition: 0.2s;
}

.rotate img {
  transform: rotate(90deg);
}

.side-bar {
  background: var(--dark-bg-color);
  color: var(--text-color);
  width: 256px;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.side-bar-logo-wrapper {
  width: 100%;
  padding: 24px;
}

.side-bar-logo-wrapper img {
  height: auto;
  width: 100%;
}

.side-list-wrapper {
  padding: 16px;
  z-index: 100;
}

.side-list-card {
  color: var(--dark-bg-color);
  max-height: 0px;
  transition: 0.5s ease-in-out;
  overflow: hidden;
}

.side-list-inner-wrapper {
  margin-bottom: 48px;
  flex: 1 1;
  overflow: auto;
}

.side-list {
  margin-bottom: -1px;
}

.expand-side-list {
  max-height: 3000px;
}

.side-list p {
  padding: 8px 16px;
  cursor: pointer;
}

.side-list-active {
  font-weight: 600;
  background: var(--blue-selector);
  color: #fff;
}

.side-bar-toggle {
  background: #282828;
  width: 14px;
  cursor: pointer;
}
.side-bar-toggle img {
  width: auto;
  height: 24px;
}

.user-display {
  padding: 8px;
  margin: 20px;
  background: #555;
  border-radius: 8px;
  display: flex;
  align-items: center;
}

.user-display button {
  padding: 6px 12px;
}
.user-svg-wrapper {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  padding: 4px;
  background: rgb(219, 219, 219);
  background: linear-gradient(
    0deg,
    rgba(219, 219, 219, 1) 0%,
    rgba(255, 255, 255, 1) 62%
  );
}

.location-top-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  border-radius: 0 !important;
}
.location-top-bar-dark input::-webkit-input-placeholder {
  color: #bbb;
}
.location-top-bar-dark input:-ms-input-placeholder {
  color: #bbb;
}
.location-top-bar-dark input::-ms-input-placeholder {
  color: #bbb;
}
.location-top-bar-dark input::placeholder {
  color: #bbb;
}
.location-top-bar-dark input:-ms-input-placeholder {
  color: #bbb;
}
.location-top-bar-dark input::-ms-input-placeholder {
  color: #bbb;
}

.input-wrapper {
  margin: 0 8px;
  width: 250px;
}

.location-bar {
  padding: 24px 0 0 16px;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  width: 100%;
  min-height: 160px;
  height: 160px;
  transition: all 0.6s ease-in-out;
}

.location-bar-extended {
  min-height: 320px;
  height: 320px;
}

.location-button {
  padding: 16px;
  height: 120px;
  width: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  font-size: 12px;
  border-radius: 6px;
  margin-right: 16px;
  margin-bottom: 16px;
  cursor: pointer;
  text-align: center;
}

.location-button svg {
  fill: var(--grey-bg-color) !important;
}

.inverted {
  background: var(--grey-bg-color) !important;
  color: var(--text-color) !important;
}

.inverted svg {
  fill: #fff !important;
}

.dark-mode-button {
  position: absolute;
  bottom: 16px;
  left: 0;
  width: 100%;
  text-align: center;
  cursor: pointer;
  font-size: 0.8rem;
  opacity: 0.3;
  transition: 0.3s;
  -webkit-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
      user-select: none;
  color: #bbb !important;
  z-index: 50;
}
.dark-mode-button:hover {
  opacity: 1;
}

.dark {
  background: #333 !important;
  color: #eee !important;
}

.dark2 {
  background: #232429 !important;
  color: #eee !important;
}

.dark-table > th > button {
  background: #232429 !important;
  color: #eee !important;
}

.dark-table > th > button:hover {
  background: #4e505a !important;
  color: #eee !important;
}

.dark-table-row:hover {
  background: #4e505a !important;
  color: #eee !important;
}

.tab-dark {
  border: none;
}

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.3em;
  height: 2.3em;
  animation-fill-mode: both;
  animation: load7 1.8s infinite ease-in-out;
}
.loader {
  color: var(--blue-selector);
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: "";
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5em;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}
.loader-no-item {
  text-align: center;
  padding: 80px;
  font-size: 1.6rem;
}
.loader-no-item h3 {
  font-weight: 300;
  color: rgb(185, 185, 185);
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

